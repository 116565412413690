import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {AnnounceType, apiGetAnnouncements, IAnnouncement} from '@api/announce/getAnnouncements'
import {useRouter} from 'next/router'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useMemo} from 'react'

export const PAGE_SIZE = 8
const useQueryGetAnnouncements = (pageSize?: number, category?: AnnounceType, currentPage?: number, id?: string) => {
    const router = useRouter()
    const {
        data: responseData,
        refetch,
        isLoading,
        fetchNextPage,
        fetchPreviousPage,
        isFetching,
        isSuccess,
    } = useInfiniteQuery({
        queryKey: QueryCacheKeys.post.getAnnouncements(category, currentPage, router?.locale),
        staleTime: 120 * 1000,
        cacheTime: 120 * 1000,
        queryFn: async ({pageParam}) => {
            const {data, pageInfo} = await apiGetAnnouncements(
                category,
                String(pageParam).length >= 10 ? currentPage : pageParam ?? currentPage,
                pageSize ?? PAGE_SIZE,
                String(pageParam).length >= 10 ? pageParam : undefined,
                router?.locale,
            )
            return {data: data, pageInfo: pageInfo}
        },
        getPreviousPageParam: (firstPage, allPages) => {
            const currentItemIndex = firstPage.data?.findIndex(e => e?.id === id)
            const prevPage = Math.floor(((firstPage.pageInfo?.current_page - 1) * PAGE_SIZE + 1) / PAGE_SIZE)
            if (currentItemIndex === 0 && currentPage > 1 && prevPage > 0) {
                return prevPage
            }
        },
        getNextPageParam: (lastPage, allPages) => {
            if (id && lastPage) {
                const currentItemIndex = lastPage.data?.findIndex(e => e?.id === id)
                if (currentItemIndex === 0) {
                    return undefined
                }
                if (currentItemIndex + 1 === lastPage.data.length) {
                    return lastPage.data[currentItemIndex]?.important_at
                }
                if (currentItemIndex + 2 === lastPage.data.length) {
                    return lastPage.data[currentItemIndex + 1]?.important_at
                }
            }
        },
    })
    const announcements = useMemo(() => {
        return responseData?.pages.reduce<IAnnouncement[]>((prev, curr) => {
            return prev.concat(curr.data)
        }, [])
    }, [responseData])
    const pageInfo = useMemo(() => responseData?.pages?.map(page => page?.pageInfo).pop(), [responseData])

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])
    return {
        announcements,
        pageInfo: pageInfo,
        refetch,
        isLoading,
        fetchNextPage,
        fetchPreviousPage,
        isFetching,
        isSuccess,
    }
}

export default useQueryGetAnnouncements
